export default {
  base: '基础设置',
  preference: '常用偏好设置',
  fingerprint: '指纹偏好设置',
  global: '全局设置',
  openAtLogin: '加入开机启动',
  openAtLoginTips: '若加入开机启动无效，请下载最新版软件并重新安装',
  download: '立即下载',
  cacheDir: '浏览器缓存',
  changeCacheDir: '变更缓存目录',
  clearCache: '清空全部缓存',
  cacheClearRule: '请输入大于1的整数天数',
  autoDeleteCache: '自动删除缓存（天数）',
  days: '天',
  daysPlaceholder: '请输入天数',
  autoDeleteTips: '填写后，自动删除此天数之前的缓存，不填写则不自动删除',
  detectAndDelete: '立即检测并删除',
  autoPasteDelay: '仿真输入延迟',
  autoPastePlaceholder: '请输入毫秒数',
  autoPasteTips1: '温馨提示：此处输入值为毫秒，1000毫秒=1秒',
  autoPasteTips2: '模拟仿真输入延迟毫秒数，输入值越大，延迟就越大，整条信息输入完需要的时间也越久，建议设置不超过500毫秒！',
  autoPasteTips3: '使用方法：在网页的输入框中，按快捷键Ctrl+Shift+E，将会把剪贴板中的内容逐个按照您设定的延迟速度输入进去。',
  milliseconds: '毫秒',
  batchOpenInterval: '批量打开店铺时间间隔',
  batchOpenPlaceholder: '请输入毫秒数',
  batchOpenTips: '批量打开多个店铺时，中间停顿的时间间隔，数值越大间隔越长',
  batchOpenRule: '请输入大于0的整数毫秒数',
  badge: '自定义浏览器角标',
  badgeEmpty: '不展示角标',
  badgeBySeq: '展示店铺序号',
  badgeByName: '展示店铺名称（截取店铺名称的前3位）',
  badgeByRemark: '展示店铺备注（截取店铺备注的前3位）',
  badgeById: '展示店铺ID（截取店铺ID的后5位）',
  badgeBySort: '展示自定义排序序号（截取自定义序号的后5位）',
  performance: '开启性能监控',
  performanceTips: '开启后，将在打开店铺时检查电脑性能，在电脑性能不足时，将停止继续打开浏览器店铺',
  performanceValue: '极限数值',
  minMemory: '内存大于',
  minDiskSpace: '缓存所在磁盘剩余空间小于',
  performanceTips2: '性能监控触发极限数值时，将停止打开浏览器店铺',
  localApi: '本地接口',
  logs: '系统日志',
  openDir: '打开',
  netDetect: '网络环境检测',
  detect: '立即检测',
  saveBase: '保存基础设置',
  lumautoTitle: 'Luminati代理设置',
  oxylabsautoTitle: 'Oxylabs代理设置',
  iphtmlautoTitle: 'IPHTML代理设置',
  ipideaautoTitle: 'IPIDEA代理设置',
  ip2worldTitle: 'IP2WORLD代理设置',
  lunaproxyTitle: 'Lunaproxy代理设置',
  bookmarksTitle: '导入Chrome书签',
  freeSpaceRule1: '值必须大于或等于1',
  freeSpaceRule2: '最多两位小数',
  sureToLeave: '指纹偏好设置未保存，是否确认离开？',
  changeDirConfirm: '变更缓存目录不会自动复制缓存文件到新目录，如有需要，请手工复制。<br/><br/>当前缓存目录：{dir}',
  clearCacheConfirm: '该功能会清除全部缓存信息（如果本机登录多个不同指纹浏览器账号，那么所有不同账号下的店铺缓存信息都将一并清空）',
  clearCacheSuccess: '已清空所有店铺环境缓存',
  saveFailed: '配置保存失败，请重试或尝试重启软件后再进行保存',
  upgradeTips: '此功能最新版本客户端可用，请升级客户端',
  bookmarksTips: '提示：选择书签文件，自动识别书签，打开所有店铺都将导入该处书签；',
  bookmarksLabel: '选择通过Chrome导出的html书签文件',
  bookmarksBar: '书签栏',
  noBookmarks: '暂无书签',
  otherBookmarks: '其他书签',
  bookmarksClearConfirm: '确认清空所有书签吗？',
  autoComplete: '浏览器自动填充用户名密码',
  autoCompleteTips: '提示：此功能将全局为您所有的浏览器店铺，指定的网站自动填充用户名、密码！',
  editAutoComplete: '编辑用户名密码',
  addAutoComplete: '添加用户名密码',
  autoCompleteUrl: '网站',
  autoCompleteUsername: '用户名',
  autoCompletePassword: '密码',
  urlRule1: '请填写网站url',
  urlRule2: '请输入正确的url',
  usernameRule: '请输入用户名',
  passwordRule: '请输入密码',
  deleteAutoCompleteConfirm: '您确定要删除此用户名密码吗？',
  duplicatedAutoComplete: '已经添加了相同的密码',
  add: '添加',
  ipWhitelist: '代理IP黑名单',
  ipWhitelistTips: '提示：添加域名到代理IP黑名单后，对应域名将不走代理IP网络而是走本地网络，域名支持通配符，如 *.amazon.com',
  domainPlaceholder: '请输入域名，注意不带协议开头，如：baidu.com',

  domain: '域名',
  ipBtns: '常用网站快捷设定（节省代理网络流量）',
  fbBtn: '打开浏览器时，使用本地网络加载Amazon静态资源',
  domainRequired: '请输入域名',
  noProtocol: '无需http | https 协议开头',
  domainRepeat: '已添加此域名',
  saveAndDownDir: '文件保存及下载目录',
  saveAndDownDirTips: '设置后，所有店铺的文件保存及下载目录，将自动使用此目录下的对应店铺序号文件夹',
  selectDir: '选择目录',
  selectFileDir: '文件选择及上传目录',
  selectFileDirTips: '设置后，进行文件选择及上传时，将默认使用此目录',
  pageZoom: '网页缩放',
  pageZoomTips: '全局设置所有店铺的网页缩放比例',
  extensionSwitch: '展示“跨店铺同步扩展应用数据”开关',
  extensionSwitchTips: '开启后，在店铺的“常用设置”项中，开启“同步扩展应用数据”开关后，将展示此开关项！',
  closeSoftware: '关闭软件时',
  rememberChoose: '记住选择',
  toTrayAtLaunch: '软件打开后最小化至托盘',
  enableGoogleTranslate: '支持中国代理IP使用谷歌翻译',
  enableGoogleTranslateTip: '开启后，店铺在使用中国代理IP的情况下，也可以使用谷歌翻译',
  minimize: '任务栏最小化',
  maximize: '屏幕最大化',
  brwoserOpenForm: '店铺打开形式',
  brwoserOpenFormTip: '设置后，所有的店铺将以您设置的方式打开',
  defaultPosition: '默认位置正常打开',
  bookmarksMode: '书签导入模式',
  mergeMode: '合并',
  replaceMode: '替换',
  mergeTips: '打开的店铺，将合并显示原有的书签与全局导入的书签；如原有书签A，全局设置书签B，则打开的店铺中，将会同时显示书签A与B。',
  replaceTips:
    '打开的店铺，将替换掉原有书签，仅显示全局导入的书签；如店铺原有书签A，全局设置书签B，则打开的店铺中，将会只显示全局设置中的书签B。',
  sysProxy: '系统代理配置',
  sysProxyTips: '',
  sysProxyServer: '代理服务器',
  sysProxyOpt1: '自动检测系统代理配置',
  sysProxyOpt2: '不使用系统代理配置',
  sysProxyOpt3: '自定义系统代理端口',
  sysProxyPort: '自定义端口',
  sysProxyPortPlaceholder: '请输入自定义端口',
  positionTips: '默认左上角(x: 0, y: 0)',
  detectSuccess: '检测成功：',
  detectFailed: '检测失败',
  processProtect: '进程守护',
  processProtectTips: '注意：当运行脚本时，发现有进程残留的情况下，可配置以下选项，定时清理残留进程，0为关闭此功能。',
  processProtectPeriod: '间隔时间（分钟）'
}
